import {Injectable} from '@angular/core';
import {BaseService} from '../../helpers/base.service';
import {Observable} from 'rxjs/internal/Observable';
import {from} from 'rxjs';
import {User, UserAccount, UserAccounts, CreateUser} from '../models/user.interface';
import {map} from 'rxjs/operators';
import {dbContext} from 'src/app/shared/offline/offline-db.service';
import { storage } from 'src/app/shared/local-storage/local-storage.service';
import {LocalStorageNames} from "../../shared/local-storage/local-storage.enum";

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {

  doctors: UserAccount[];
  allUsers: UserAccount[];

  public login(username: string, password: string, keepLoggedIn: boolean): Observable<any> {

    const route = `${this.apiUrl}/Account/Login`;
    const body = JSON.stringify({username, password, keepLoggedIn});

    return this.http.post<any>(route, body, this.headers()).pipe(map(user => {

      storage.setItem(LocalStorageNames.CurrentUser, user);
      this.currentUserSubject.next(user);

      return user;
    }));
  }


  public createClientLogin(clientId: string): Observable<any> {

    const route = `${this.apiUrl}/User/CreateClientUser/${clientId}`;


    return this.http.post<any>(route, null, this.headers()).pipe(map(user => {
      return user;
    }));
  }

  public impersonate(userId: string): Observable<any> {

    const route = `${this.apiUrl}/Account/ImpersonateUser/${userId}`;


    return this.http.post<any>(route, null, this.headers()).pipe(map(user => {

      storage.setItem(LocalStorageNames.CurrentUser, user);
      this.currentUserSubject.next(user);

      return user;
    }));
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get isSystemAdmin(): boolean {

    if (this.currentUserSubject.value.roles) {
      const roles = this.currentUserSubject.value.roles.filter(x => x === 'SystemAdministrator');

      if (roles.length > 0) {
        return true;
      }
    }
    return false;
  }

  public get isSystemAdminOrVetOrTech(): boolean {

    if (this.currentUserSubject.value.roles) {
      const roles = this.currentUserSubject.value.roles.filter(x => x === 'SystemAdministrator' || x === 'Vet' || x === 'Techs');

      if (roles.length > 0) {
        return true;
      }
    }
    return false;
  }

  public get canFinalize(): boolean {

    if (this.currentUserSubject.value.roles) {
      const roles = this.currentUserSubject.value.roles.filter(x =>
        x === 'SystemAdministrator'
        || x === 'BusinessOffice'
        || x === 'FrontOffice');

      if (roles.length > 0) {
        return true;
      }
    }
    return false;
  }

  logout() {
    storage.removeItem(LocalStorageNames.CurrentUser);
    this.currentUserSubject.next(null);
  }

  public getUserAccountsList() {

    const route = `${this.apiUrl}/Account/GetUserAccounts`;

    return this.http.get<UserAccounts>(route, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public getClientAccountsList() {

    const route = `${this.apiUrl}/Account/GetClientAccounts`;

    return this.http.get<UserAccounts>(route, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public getTechs(): Observable<UserAccount[]> {

    const route = `${this.apiUrl}/User/GetTechs`;

    return this.http.get<UserAccount[]>(route, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public async getDoctors() {


    if (this.doctors == null) {
      // tslint:disable-next-line: max-line-length
      this.doctors = (await dbContext.doctors.toArray()).filter(x => x.disabled === false && x.isVet === true);
    }

    return this.doctors;
  }

  public async getAllUsers() {


    if (this.allUsers == null) {
      // tslint:disable-next-line: max-line-length
      this.allUsers = (await dbContext.doctors.toArray()).filter(x => x.disabled === false);
    }

    return this.allUsers;
  }

  public getAllUsersObservable(): Observable<UserAccount[]> {
    return from(this.getAllUsers());
  }

  public setCalendarDoctors(userId: string, calendarDoctors: string[]): any {

    const route = `${this.apiUrl}/User/CalendarDoctors/${userId}`;

    return this.http.post<any>(route, calendarDoctors, this.headers()).pipe(map(response => {

      return response;
    }));
  }


  public getFinishedUsers(): Observable<UserAccount[]> {

    const route = `${this.apiUrl}/User/GetFinishedUsers`;

    return this.http.get<UserAccount[]>(route, this.headers()).pipe(map(response => {

      return response;
    }));
  }

  public createAccount(createAccountModel: CreateUser) {
    const route = `${this.apiUrl}/Account/AddUser`;
    const body = createAccountModel;


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public getIncompleteAccount(_userId, _token) {
    const route = `${this.apiUrl}/User/GetIncompleteAccount`;
    const body = {
      userId: _userId,
      token: _token
    };


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public confirmAccount(confirmAccountModel: any) {
    const route = `${this.apiUrl}/User/CompleteAccount`;
    const body = confirmAccountModel;


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public resendConfirmation(_email: string) {
    const route = `${this.apiUrl}/User/ResendConfirmation`;
    const body = {email: _email};


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public resendClientConfirmation(_email: string) {
    const route = `${this.apiUrl}/User/ClientResendConfirmation`;
    const body = {email: _email};


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public sendClientForgotPasswordEmail(_email: string) {
    const route = `${this.apiUrl}/User/ClientForgotPasswordEmail`;
    const body = {email: _email};

    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public disableClient(clientId: string) {
    const route = `${this.apiUrl}/User/DisableClientUser/${clientId}`;


    return this.http.post<any>(route, null, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public sendForgotPasswordEmail(_email: string) {
    const route = `${this.apiUrl}/User/ForgotPasswordEmail`;
    const body = {email: _email};


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }

  public updateAccount(updateAccountModel: any) {
    const route = `${this.apiUrl}/User/UpdateAccount`;
    const body = updateAccountModel;


    return this.http.post<any>(route, body, this.headers()).pipe(map(response => {
      return response;
    }));
  }
}
