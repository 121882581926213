import {Component, OnDestroy, OnInit} from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import {UserAccount, UserOption} from 'src/app/configuration-management/models/user.interface';
import { DiscountSummary } from 'src/app/configuration-management/models/discounts.interface';
import { LocationService } from 'src/app/configuration-management/services/location.service';
import {LineItemLocation} from '../../../../../line-item-entry/models/line-item.interface';
import {DiscountsService} from '../../../../../configuration-management/services/discounts.service';
import {AccountService} from '../../../../../configuration-management/services/account.service';
import {LocationSummary} from '../../../../../configuration-management/models/locations.interface';
import {Subscription} from 'rxjs';

const unchangedName = '';
const resetName = '(Clear Selected)';

export class UpdateLineItems {
  dateOfWork: Date;
  doctor = this.unchangedDoctor();
  asstDoctor = this.unchangedDoctor();
  discount: DiscountSummary = this.unchangedDiscount();
  location: LineItemLocation = this.unchangedLocation();

  unchangedDoctor(): UserAccount {
    return {id: null, name: unchangedName} as UserAccount;
  }

  unchangedDiscount(): DiscountSummary {
    return {id: null, discountPercentage: unchangedName} as DiscountSummary;
  }

  unchangedLocation(): LineItemLocation {
    return {id: null, name: unchangedName} as unknown as LineItemLocation;
  }

  resetDoctor(): UserAccount {
    return {id: '', name: resetName} as UserAccount;
  }

  resetDiscount(): DiscountSummary {
    return {id: '', discountPercentage: resetName} as DiscountSummary;
  }

  resetLocation(): LineItemLocation {
    return {id: '', name: resetName} as unknown as LineItemLocation;
  }
}

@Component({
  selector: 'app-update-line-items',
  templateUrl: 'update-line-items.dialog.html',
  styleUrls: ['./update-line-items.dialog.scss']
})
export class UpdateLineItemsComponent implements OnInit, OnDestroy {

  updateLineItems = new UpdateLineItems();
  doctors: UserOption[];
  doctorsWithEmpty: UserOption[];
  discounts: DiscountSummary[];
  locations: LineItemLocation[];
  doctorsSearchCtrl = null;
  locationsSearchCtrl = null;
  subscriptions: Subscription;
  constructor(
    public dialogRef: MatDialogRef<UpdateLineItems>,
    public discountsService: DiscountsService,
    public accountService: AccountService,
    public locationService: LocationService
  ) { }

  ngOnInit() {
    this.getDiscounts();
    this.subscriptions = this.locationService.getInventoryLocations().subscribe(this.setLocations.bind(this));
    this.getDoctors();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getDiscounts() {
    this.discountsService.getActiveDiscounts().then(res => {
      this.discounts = res.slice();

      // Add option to unset the discount
      this.insertOptionAtStart(this.discounts, this.updateLineItems.resetDiscount());

      // Add option to leave the discount alone
      this.insertOptionAtStart(this.discounts, this.updateLineItems.unchangedDiscount());
    });
  }

  getDoctors() {
    this.subscriptions.add(this.accountService.getDoctorOptions().subscribe(res => {
      this.doctors = res.slice();
      this.doctorsWithEmpty = this.doctors.slice();

      // Add option to unset the assistant doctor
      this.insertOptionAtStart(this.doctorsWithEmpty, this.updateLineItems.resetDoctor());

      // Add option to leave the doctors alone
      this.insertOptionAtStart(this.doctors, this.updateLineItems.unchangedDoctor());
      this.insertOptionAtStart(this.doctorsWithEmpty, this.updateLineItems.unchangedDoctor());
    }));
  }

  setLocations(res: LocationSummary[]) {
    // The location service returns LocationSummaries, which can
    // be coerced into a valid LineItemLocations
    this.locations = res.map(v => (JSON.parse(JSON.stringify(v)) as LineItemLocation));

    // Add option to leave the location alone
    this.insertOptionAtStart(this.locations, this.updateLineItems.unchangedLocation());
  }

  verifyModel() {

  }

  setAsstDoctor(doctorName: string) {
    const doctor = this.doctorsWithEmpty.find(x => x.name === doctorName);
    this.updateLineItems.asstDoctor = JSON.parse(JSON.stringify(doctor));
  }

  setDoctor(doctorName: string) {
    const doctor = this.doctors.find(x => x.name === doctorName);
    this.updateLineItems.doctor = JSON.parse(JSON.stringify(doctor));
  }

  private insertOptionAtStart(options: any[], newOption: any) {
    options.splice(0, 0, newOption);
  }
}
